<template>
	<div>
		<DetailTemplate :custom-class="'contact-detail detail-page'">
			<template v-slot:header-topbar>
				<div class="py-2 border-bottom">
					<div class="d-flex align-center">
						<div class="d-flex align-center">
							<div class="font-weight-bold text-h4 text-capitalize" style="color: #0d47a1">
								{{ contact.salutation }}. {{ contact.contact_name }}
								<v-icon color="orange">mdi-crown</v-icon>
							</div>
							<!-- <v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>
							<div class="text-h5 font-weight-bold">$123.00</div> -->
						</div>
						<v-spacer></v-spacer>
						<v-chip
							class="ml-1 px-2 text-capitalize"
							color="t"
							outlined
							small
							v-if="contact && contact.barcode"
							>{{ contact.barcode }}
						</v-chip>
						<template v-if="isOppStatus">
							<!-- <v-btn
								v-if="isAccepted"
								small
								rounded
								color="green darken-4 text-white"
								class="ml-2"
								depressed
								>Accepted
							</v-btn> -->
							<v-btn v-if="isRejected" small rounded color="red darken-4 text-white" class="ml-2" depressed
								>Rejected
							</v-btn>
							<v-btn @click="changeColor('reopen')" outlined color="grey" class="ml-2" depressed tile
								>Reopen
							</v-btn>
						</template>
						<template v-else>
							<!-- <v-btn
								@click="changeColor('accept')"
								color="green darken-4 text-white"
								class="ml-2"
								depressed
								tile
								>Accepted
							</v-btn> -->
							<!-- <v-btn
								@click="changeColor('reject')"
								color="red darken-4 text-white"
								class="ml-2"
								depressed
								tile
								>Rejected
							</v-btn> -->
						</template>
						<!-- <v-menu offset-y left bottom content-class="elevation-3 border" rounded="0" max-width="180px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="blue darken-4 text-white" v-on="on" v-bind="attrs" class="ml-2" depressed tile
									><v-icon left small class="mdi-rotate-180">mdi-more</v-icon>More
								</v-btn>
							</template>
							<v-list class="pa-2">
								<v-list-item class="px-2" v-on:click="open_opportunity_drawer('Clone')">
									<v-list-item-title> Clone</v-list-item-title>
								</v-list-item>
								
							</v-list>
						</v-menu> -->
						<v-btn depressed tile class="my-auto ms-2" v-on:click="goBackData()">
							<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
							back
						</v-btn>
					</div>
					<div class="d-flex align-center">
						<span>
							<v-icon small color="orange">mdi-domain</v-icon>
							<span class="font-weight-bold ms-1 text-capitalize" style="color: orange">{{
								contact?.company?.company_name
							}}</span>
						</span>
						<v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>
						<span>Created date :&nbsp;</span>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<v-chip class="text-capitalize" color="blue white--text" x-small label
										><v-icon class="me-1" x-small>mdi-clock-outline</v-icon
										>{{ formatDateTime(contact.added_at) }}</v-chip
									>
								</span>
							</template>
							<span>Created date</span>
						</v-tooltip>
						<v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>
						<span>Last contacted :&nbsp;</span>
						<span
							>{{ formatDateTime(contact.updated_at)
							}}<b class="blue--text ms-1">({{ diffInDays }})</b></span
						>
					</div>
					<!-- <div class="step-status mt-2">
						<v-tabs
							v-model="statusTab"
							background-color="transparent"
							grow
							color="green"
							active-class="green darken-4 text-white"
							hide-slider
						>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-tab v-bind="attrs" v-on="on" :class="statusTab <= 4 ? activeTabClass : ''">
										Not Connected ({{formatMoney(notcontactedstatus)}})</v-tab
									>
								</template>
								<span>Not Connected</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-tab
										v-bind="attrs"
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 1 ? activeTabClass : ''"
									>
										Follow up ({{formatMoney(followstatus)}})</v-tab
									>
								</template>
								<span>Follow up</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-tab
										v-bind="attrs"
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 2 ? activeTabClass : ''"
									>
										Meeting ({{formatMoney(meetingstatus)}})</v-tab
									>
								</template>
								<span>Meeting</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-tab
										v-bind="attrs"
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 3 ? activeTabClass : ''"
									>
										Quotation ({{formatMoney(quotationstatus)}})</v-tab
									>
								</template>
								<span>Quotation</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-tab
										v-bind="attrs"
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 4 ? activeTabClass : ''"
									>
										Negotiation ($300.00)</v-tab
									>
								</template>
								<span>Negotiation</span>
							</v-tooltip>
						</v-tabs>
					</div> -->
				</div>
			</template>

			<template v-slot:sidebar-detail>
				<div class="listing-sidebar">
					<v-col md="12">
						<v-hover v-slot="{ hover }">
							<div class="p-10 text-center">
								<v-progress-circular
									indeterminate
									:size="100"
									:width="10"
									color="cyan"
									v-if="primaryLoader"
								></v-progress-circular>
								<v-avatar size="140" v-else>
									<v-img
										max-height="140px"
										max-width="140px"
										:src="
											contact_logo
												? contact_logo
												: 'https://fsm-v3.businessthrust.com/media/default/no-profile-image.png'
										"
										class="custom-grey-border margin-auto custom-border-radius-50"
									>
									</v-img>
								</v-avatar>
								<template v-if="getPermission('contact:update')">
									<div class="engineer-image-action" style="position: relative; top: -30px; right: -45px">
										<v-btn
											color="blue darken-4 text-white"
											class="mr-2 mt-2 rounded-circle"
											depressed
											x-small
											rounded
											fab
											tile
											v-on:click="selectImage"
											><v-icon small>mdi-image-plus</v-icon>
										</v-btn>

										<div class="d-none">
											<v-file-input
												v-model.trim="rawImage"
												ref="uploadImage"
												accept="image/png, image/jpeg, image/bmp"
											></v-file-input>
										</div>
									</div>
								</template>
							</div>
						</v-hover>
					</v-col>
					<!-- <div class="text-center mb-3 pt-4">
						<v-avatar color="grey lighten-3" size="70">
							<v-img src="https://fsm-v3.businessthrust.com/media/default/no-profile-image.png"></v-img>
						</v-avatar>
					</div> -->
					<div class="d-flex justify-center mb-1" v-if="single_meeting && single_meeting.start_from">
						<div class="detail-label">Meeting Date : &nbsp;</div>
						<div class="value">
							<b v-if="color == 1"
								><span style="color: red">{{ formatDate(single_meeting?.start_from) }}</span></b
							>
							<b v-else>{{ formatDate(single_meeting?.start_from) }}</b>
						</div>
						<!-- <em class="text-muted" v-else>no meeting date</em> -->
					</div>
					<div class="d-flex justify-center mb-3" v-if="single_meeting && single_meeting.start_time">
						<div class="detail-label">Meeting Time : &nbsp;</div>
						<div class="value">
							<b v-if="color == 1"
								><span style="color: red">{{ formatTime22(single_meeting?.start_time) }}</span></b
							>
							<b v-else>{{ formatTime22(single_meeting?.start_time) }}</b>
						</div>
						<!-- <em class="text-muted" v-else>no meeting time</em> -->
					</div>
					<div class="d-flex justify-center mb-1">
						<div class="detail-label">Reminder Date : &nbsp;</div>
						<div class="value">
							<template v-if="single_reminder && single_reminder.start_date">
								<b v-if="color1 == 1"
									><span style="color: red">{{ formatDate(single_reminder?.start_date) }}</span>
								</b>
								<b v-else>{{ formatDate(single_reminder?.start_date) }}</b>
								<UpdateReminderMenu
									:edit-reminder.sync="editReminder"
									:id="single_reminder.id"
									v-on:close="editReminder = false"
									v-on:success="findContact"
								/>
							</template>
							<em v-else class="text-muted"> no reminder </em>
						</div>
					</div>
					<div class="d-flex justify-center mb-1">
						<div class="detail-label">Reminder Time : &nbsp;</div>
						<div class="value">
							<template v-if="single_reminder && single_reminder.start_date">
								<b v-if="color1 == 1"
									><span style="color: red">{{ formatTime(single_reminder?.start_date) }}</span></b
								>
								<b v-else>{{ formatTime(single_reminder?.start_date) }}</b>
							</template>
							<em v-else class="text-muted"> no time </em>
						</div>
					</div>
					<table class="w-100 side-table mt-4">
						<tr>
							<td colspan="3">
								<div class="font-level-3-bold pa-1" style="background-color: #f1e8e8">Contact Info</div>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Contact</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500 text-capitalize" v-if="contact && contact.contact_name">
									{{ contact.salutation }}. {{ contact.contact_name }}
								</span>
								<em class="text-muted" v-else>No contact name</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Email</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.email_address">
									<v-icon small>mdi-email</v-icon>{{ contact.email_address }}
								</span>
								<em class="text-muted" v-else>No email</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Mobile</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.phone_number">
									<v-icon small>mdi-cellphone</v-icon>{{ contact.phone_number }}
								</span>
								<em class="text-muted" v-else>No number</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">LandLine</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.land_line_number">
									<v-icon small>mdi-phone</v-icon>{{ contact.land_line_number }}
								</span>
								<em class="text-muted" v-else>No landLine</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">DID</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.did_number">
									<v-icon small>mdi-phone</v-icon>{{ contact.did_number }}
								</span>
								<em class="text-muted" v-else>No did</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Fax</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.fax">
									<v-icon small>mdi-phone</v-icon>{{ contact.fax }}
								</span>
								<em class="text-muted" v-else>No fax</em>
							</td>
						</tr>
						

						<tr>
							<td class="py-1" style="width: 95px">Designation</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.designation">
									<v-chip class="ml-1 px-2 text-capitalize text-white" small color="green"
										>{{ contact.designation }}
									</v-chip>
								</span>
								<em class="text-muted" v-else>No designation</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" valign="top" style="width: 95px">Department</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact.department">
									<v-chip class="ml-1 px-2 text-capitalize text-white" small color="blue"
										>{{ contact.department }}
									</v-chip>
								</span>
								<em class="text-muted" v-else>No department</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" valign="top" style="width: 95px">Tags</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="contact && contact?.tag?.length > 0">
									<v-chip
										class="ml-1 px-2 text-capitalize text-white"
										small
										v-for="(tag, index) in contact.tag"
										:key="index"
										:color="tag.color"
										>{{ tag.text }}
									</v-chip>
								</span>
								<em class="text-muted" v-else>No tags</em>
							</td>
						</tr>
					</table>
					<table class="w-100 side-table mt-4">
						<tr>
							<td colspan="3">
								<div class="font-level-3-bold pa-1" style="background-color: #f1e8e8">Related Company</div>
							</td>
						</tr>
						<!-- {{company.contact[0]}} -->
						<tr>
							<td class="py-1" style="width: 95px">Company</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="text-capitalize" v-if="contact?.company?.company_name">{{
									contact?.company?.company_name
								}}</span>
								<em class="text-muted" v-else>No Company</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Email</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<template v-if="contact?.company?.email">
									<v-icon small>mdi-email</v-icon>{{ contact?.company?.email }}
								</template>
								<template v-else>
									<em class="text-muted">No Email</em>
								</template>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Phone</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<template v-if="contact?.company?.phone">
									<v-icon small>mdi-phone</v-icon>{{ contact?.company?.phone }}
								</template>
								<template v-else>
									<em class="text-muted">No Phone</em>
								</template>
							</td>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:body>
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="px-3 py-2 flex-wrap">
							<div class="flex-grow-11">
								<v-tabs
									v-model="assetTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#activity"><v-icon small left>mdi-wrench-outline</v-icon>Activity</v-tab>
									<v-tab href="#opportunity">
										<v-icon small left>mdi-offer</v-icon>Opportunity ({{ opportunity?.length }})</v-tab
									>
									<!-- <v-tab href="#overview"><v-icon small left>mdi-note-plus-outline</v-icon>Overview</v-tab> -->
									<v-tab href="#meeting" @change="handleTabChange('meeting')">
										<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting ({{
											all_count_meeting[0]?.meetings_all
										}})</v-tab
									>
									<v-tab href="#reminder" @change="handleTabChange('reminder')">
										<v-icon small left>mdi-alarm-check</v-icon>Reminder({{
											reminderCounting?.all_reminders_count
										}})</v-tab
									>
								</v-tabs>
							</div>
						</v-layout>
					</div>
					<div class="tab-layout-content">
						<v-tabs-items v-model="assetTab">
							<v-tab-item value="activity">
								<div>
									<ActivityTabNew :logs="logData" heading="Contact"></ActivityTabNew>
								</div>
							</v-tab-item>
							<v-tab-item value="opportunity">
								<div class="custom-height">
									<CommomListing
										:tbody="opportunity"
										:types="1"
										:sourceList="sourceList"
										:allowedSortedThead="allowedSortedThead"
									></CommomListing>
								</div>
							</v-tab-item>
							<v-tab-item value="overview" v-if="false">
								<v-row class="">
									<v-col md="8">
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Description</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="contactData.description">
													{{ contactData.description }}
												</template>
												<template v-else>
													<em class="text-muted">No description</em>
												</template>
											</div>
										</div>
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Internal Remark</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="contactData.remark">
													{{ contactData.remark }}
												</template>
												<template v-else>
													<em class="text-muted">No Internal Remark</em>
												</template>
											</div>
										</div>
									</v-col>
									<v-col md="4">
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Address</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="contactData && contactData.address">
													<div class="d-flex align-baseline">
														<v-icon class="me-1" small>mdi-map</v-icon>72 Bendemeer Rd,<br />
														#07-03 Luzerne S(),<br />
														28 Lor 23 Geylang 00074,<br />
														Singapore,<br />
														11002
													</div>
												</template>
												<template v-else>
													<em class="text-muted">No address</em>
												</template>
											</div>
											<div class="google-map px-2 y-3 text h5">map</div>
										</div>
									</v-col>
								</v-row>
							</v-tab-item>
							<v-tab-item value="meeting">
								<v-layout class="pa-2 border-light-grey min-height-40px">
									<v-flex class="font-level-3-bold my-auto">
										<v-icon color="blue darken-4" left>mdi-timeline-clock-outline</v-icon>
										Meeting
									</v-flex>
									<!-- <v-flex class="text-right">
										<v-btn
											v-on:click="open_meeting_drawer('Create')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-plus</v-icon>Create Meeting</v-btn
										>
									</v-flex> -->
								</v-layout>
								<v-layout class="px-3 py-2 flex-wrap meeting_tab">
									<div class="flex-grow-11">
										<v-tabs
											v-model="meetingTab"
											background-color="transparent"
											color="blue"
											class="custom-tab-transparent w-100"
											hide-slider
										>
											<v-tab class="blue-tab" href="#all" v-on:click="getMeeting(10)">
												<v-icon small left>mdi-file-chart-outline</v-icon>All ({{
													all_count_meeting[0]?.meetings_all
												}})
											</v-tab>
											<v-tab class="grey-tab" href="#upcoming" v-on:click="getMeeting(4)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Upcoming ({{
													all_count_meeting[0]?.upcoming_meetings
												}})
											</v-tab>
											<v-tab class="blue-tab2" href="#postpond" v-on:click="getMeeting(3)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Postpond ({{
													all_count_meeting[0]?.postpone_meetings
												}})
											</v-tab>
											<v-tab class="green-tab" href="#completed" v-on:click="getMeeting(1)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Completed ({{
													all_count_meeting[0]?.completed_meetings
												}})
											</v-tab>
											<v-tab class="red-tab" href="#cancelled" v-on:click="getMeeting(2)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Cancelled ({{
													all_count_meeting[0]?.cancel_meetings
												}})
											</v-tab>
										</v-tabs>
									</div>
								</v-layout>
								<v-tabs-items v-model="meetingTab">
									<v-tab-item value="all">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="upcoming">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="cancelled">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="postpond">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="completed">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
								</v-tabs-items>
							</v-tab-item>
							<v-tab-item value="reminder">
								<v-layout class="px-4 py-2 reminder_tab">
									<v-tabs
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent w-100"
										active-class="blue darken-4 text-white"
										hide-slider
										v-model="reminderTabs"
									>
										<v-tab class="red-tab" href="#overdue" v-on:click="getReminder(1)">
											<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
												reminderCounting && reminderCounting.overdue_reminders_count
													? reminderCounting.overdue_reminders_count
													: 0
											}})</v-tab
										>
										<v-tab class="blue-tab" href="#upcoming" v-on:click="getReminder(2)">
											<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
												reminderCounting && reminderCounting.upcoming_reminders_count
													? reminderCounting.upcoming_reminders_count
													: 0
											}})
										</v-tab>
										<v-tab class="green-tab" href="#complate" v-on:click="getReminder(3)">
											<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
												reminderCounting && reminderCounting.completed_reminders_count
													? reminderCounting.completed_reminders_count
													: 0
											}})</v-tab
										>
									</v-tabs>
								</v-layout>
								<div class="tab-layout-content px-4">
									<v-tabs-items v-model="reminderTabs">
										<v-tab-item value="overdue">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no overdue reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg">Related To</th>

																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>

														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="5" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span class="font-level-2-bold text-capitalize" v-if="index === 'today'"
																				>{{ formatDate(todatDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else-if="index === 'yesterday'"
																				>{{ formatDate(yesterdayDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>

																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="font-level-3-bold ms-1 text-capitalize">
																			{{ rowChild.lead_names }}
																			<div class="d-flex align-center">
																				<v-chip
																					class="ml-2 text-capitalize"
																					color="blue white--text"
																					outlined
																					x-small
																					label
																					v-if="rowChild && rowChild.lead_barcode"
																					>{{ rowChild.lead_barcode }}
																				</v-chip>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no reminders at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
										<v-tab-item value="upcoming">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no upcoming reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg">Related To</th>

																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>

														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="5" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span class="font-level-2-bold text-capitalize" v-if="index === 'today'"
																				>{{ formatDate(todatDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else-if="index === 'yesterday'"
																				>{{ formatDate(yesterdayDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>

																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="font-level-3-bold ms-1 text-capitalize">
																			{{ rowChild?.lead_name }}
																			<div class="d-flex align-center">
																				<v-chip
																					class="ml-2 text-capitalize"
																					color="blue white--text"
																					outlined
																					x-small
																					label
																					v-if="rowChild && rowChild.lead_barcode"
																					>{{ rowChild.lead_barcode }}
																				</v-chip>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no upcoming reminders at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
										<v-tab-item value="complate">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no complete reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg">Related To</th>

																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>

														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="5" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span class="font-level-2-bold text-capitalize" v-if="index === 'today'"
																				>{{ formatDate(todatDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else-if="index === 'yesterday'"
																				>{{ formatDate(yesterdayDate) }} ({{ index }})</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>

																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="font-level-3-bold ms-1 text-capitalize">
																			{{ rowChild?.lead_name }}
																			<div class="d-flex align-center">
																				<v-chip
																					class="ml-2 text-capitalize"
																					color="blue white--text"
																					outlined
																					x-small
																					label
																					v-if="rowChild && rowChild.lead_barcode"
																					>{{ rowChild.lead_barcode }}
																				</v-chip>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no complete reminders at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
									</v-tabs-items>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
				<CreateMeeting
					v-if="open_dialog.meeting"
					:drawer="open_dialog.meeting"
					:title-status="meeting_title"
					:get-meeting-data="editMeetingData"
					:attendies="attendieList"
					v-on:updateMeetingSuccess="refreshMeeting"
					v-on:close="open_dialog['meeting'] = false"
					related="company"
					:related-id="lead_id"
				></CreateMeeting>
				<CreateOrUpdateReminderV2
					v-if="open_dialog.reminder"
					:drawer="open_dialog.reminder"
					:relatedId="lead_id"
					:reminderId="edit_reminder_id"
					related="lead"
					v-on:success="refreshReminder"
					v-on:close="closeReminderDialog()"
				>
				</CreateOrUpdateReminderV2>
				<Dialog :dialog="desc_dialog" :dialog-width="600">
					<template v-slot:title> Description</template>
					<template v-slot:body>
						<v-row class="delete-dialog">
							<v-col md="2" class="py-0 text-right my-auto">
								<span class="svg-icon svg-icon-lg delete-confirm-icon">
									<!--begin::Svg Icon-->
									<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
									<!--end::Svg Icon-->
								</span>
							</v-col>
							<v-col md="12" class="py-0 my-auto">
								<div v-html="fullDescription"></div>
							</v-col>
						</v-row>
					</template>
					<template #action>
						<!-- <v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						@click="onSubmit"
					>
						Save
					</v-btn> -->
						<v-btn depressed tile @click="desc_dialog = false"> Close </v-btn>
					</template>
				</Dialog>
			</template>
			<template v-slot:footer> </template>
		</DetailTemplate>
		<AcceptedDialog
			v-if="acceptDialog"
			v-on:close="acceptDialog = false"
			:accept-dialog="acceptDialog"
		>
		</AcceptedDialog>
		<RejectedDialog
			v-if="rejectDialog"
			v-on:close="rejectDialog = false"
			:reject-dialog="rejectDialog"
		></RejectedDialog>
		<FullDialog v-if="leadsDialog" dense :dialog="leadsDialog" content-class="testdata">
			<template #title
				>Opportunity {{ leadData.barcode }} Notes
				<v-spacer></v-spacer>
				<v-btn icon class="text-white" color="red darken-4" depressed tile @click="closeDialog">
					<v-icon dark left>mdi-close</v-icon>
					Close
				</v-btn>
			</template>
			<template #body>
				<AllNotesTab
					v-if="leadsDialog"
					:relatedId="lead_id"
					:relatedType="'lead-notes'"
					:siggest-list="notesUserArr"
					create-url="lead-note"
					get-url="lead-note"
				>
				</AllNotesTab>
			</template>
			<!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
		</FullDialog>
		<div class="floating-btn-wrapper">
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165)"></div>
				<v-icon
					@click="open_reminder_drawer('Create')"
					style="font-size: 50px !important"
					color="red"
					small
					>mdi-alarm-check</v-icon
				>
			</div>
			<!-- <div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
				<v-icon style="font-size: 50px !important" color="green" small v-on:click="leadsDialog = true"
					>mdi-chat
				</v-icon>
			</div> -->
		</div>
		<ReminderCompleteTemplate
			type="Reminder"
			:completeUrl="completeURL"
			:completeText="completeTEXT"
			:completeDialog="complete_dialog"
			v-on:close="complete_dialog = false"
			v-on:success="
				complete_dialog = false;
				getReminder();
			"
			:dataObject="remind"
		></ReminderCompleteTemplate>
		<DeleteTemplate
			type="Reminder"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				getReminder();
			"
		></DeleteTemplate>
	</div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import UpdateReminderMenu from "@/view/components/UpdateReminderMenu";
// import OpportunityListing from "@/view/module/opportunity/OpportunityListing";
import AcceptedDialog from "@/view/components/AcceptedDialog";
import RejectedDialog from "@/view/components/RejectedDialog";
import { QUERY, GET, UPLOAD } from "@/core/services/store/request.module";
// import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { TaskEventBus } from "@/core/lib/task.form.lib";
import { toSafeInteger } from "lodash";
import CommomListing from "@/view/module/opportunity/CommomListing";
import ReminderCompleteTemplate from "@/view/components/ReminderCompleteTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import MomentJS from "moment-timezone";

export default {
	name: "Contact-details",
	watch: {
		rawImage(param) {
			const _this = this;

			_this.uploadAttachment(param).then((response) => {
				_this.profileImage = _this.lodash.head(response);
				//   if (_this.lodash.isEmpty(_this.profileImage) === false) {
				//     _this.$store
				//       .dispatch(PATCH, {
				//         url: "engineer/" + _this.engineer.id,
				//         data: {
				//           profile_logo: _this.profileImage.id,
				//           role: _this.engineer.role.id,
				//         },
				//       })
				//       .catch((error) => {
				//         _this.logError(error);
				//       });
				//   }
			});
		},
	},
	data() {
		return {
			rawImage: null,
			profileImage: null,
			contact_logo: "https://fsm-v3.businessthrust.com/media/default/no-profile-image.png",
			statusTab: 0,
			activeTabClass: "green darken-4 text-white v-tab--active",
			isOppStatus: false,
			opportunity: [],
			apireminder: false,
			apimeeting: false,
			single_reminder: null,
			remind: null,
			delURL: null,
			delTEXT: null,
			single_meeting: null,
			heading: "Contact",
			completeURL: null,
			completeTEXT: null,
			complete_dialog: false,
			yesterdayDate: MomentJS().subtract(1, "days").format("YYYY-MM-DD"),
			todatDate: MomentJS().format("YYYY-MM-DD"),
			contact: [],
			color: 0,
			color1: 0,
			typeId: 2,
			primaryLoader: false,
			editReminder: false,
			acceptstatus: null,
			rejectstatus: null,
			quotationstatus: 0,
			meetingstatus: 0,
			notcontactedstatus: 0,
			followstatus: 0,
			isAccepted: false,
			isRejected: false,
			acceptDialog: false,
			rejectDialog: false,
			all_count_meeting: 0,
			companyData: null,
			company: [],
			rerenderKey: Number(new Date()),

			confirm_dialog: false,
			isDisabled: false,
			desc_dialog: false,
			fullDescription: "",
			assetTab: "assetTab",
			meetingTab: "all",
			open_details: false,
			lead_id: 0,
			meeting_title: null,
			editMeetingData: null,
			meetingData: [],
			attendieList: {},
			reminderData: null,
			reminderCounting: null,
			diffInDays: 0,
			leadsDialog: false,
			leadData: {},
			reminderTabs: "overdue",
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
			},
			sourceList: [],
			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 165,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "pink",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "cyan",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 148,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
			],
			logData: [
				{
					id: 1,
					module: "Company",
					mid: 515,
					description: "Company #BT-0000498 Updated",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-04-13 19:02:47",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "123.0.0.0",
					robot: null,
					languages: '["en-us", "en", "hi"]',
					user_name: "admin",
					user_profile:
						"https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png",
				},
				{
					id: 2,
					module: "Company",
					mid: 515,
					description: "Company #BT-0000498 Updated",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-04-10 19:01:12",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "123.0.0.0",
					robot: null,
					languages: '["en-us", "en", "hi"]',
					user_name: "admin",
					user_profile:
						"https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png",
				},
				{
					id: 3,
					module: "Company",
					mid: 515,
					description: "Company #BT-0000498 Created ",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-03-01 16:28:58",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "122.0.0.0",
					robot: null,
					languages: '["en-us", "en"]',
					user_name: "admin",
					user_profile:
						"https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/b2f1fe64-0645-43d9-81eb-88e61638588c.png",
				},
			],
			contactData: {
				barcode: "BTC-000001",
				company_name: "Abc Corp",
				website: "bthrust.com",
				product_type: "Seo",
				created_date: "01/03/2024",
				contact: "",
				contact_email: "info@gmail.com",
				contact_number: "+65 1234 567",
				company: "",
				tag: [{ id: 1, text: "tags-1", color: "#83b727" }],
				company_email: "abc@gmail.com",
				company_number: "+65 1234 5678",
				display_name: "admin",
				remark:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam purus nisi, mattis quis laoreet eget, auctor eu metus. Etiam a mi ut leo cursus porta. Vivamus ac pulvinar eros, ut aliquam dolor. Quisque risus orci, placerat eget dapibus et",
				description:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam purus nisi, mattis quis laoreet eget, auctor eu metus. Etiam a mi ut leo cursus porta. Vivamus ac pulvinar eros, ut aliquam dolor. Quisque risus orci, placerat eget dapibus et",
				address: "72 Bendemeer Rd, #07-03 Luzerne S(), 28 Lor 23 Geylang, 00074, Singapore, 11002",
				last_contact_formated: "06/06/2024",
				opportunity: "High-Chance",
				meeting: "Business Related",
				meeting_date: "06/06/2024",
				meeting_time: "9:30 AM",
				reminder_date: "05/06/2024",
				reminder_time: "9:30 AM",
			},
			allowedSortedThead: [
				{
					id: 270,
					type: "opportunity",
					key: "pin",
					name: "Pin",
					sort: null,
					fixed: true,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 1,
				},
				{
					id: 271,
					type: "opportunity",
					key: "opportunity",
					name: "Opportunity Name",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 2,
				},
				{
					id: 272,
					type: "opportunity",
					key: "stage",
					name: "Stage",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 3,
				},
				{
					id: 273,
					type: "opportunity",
					key: "priority",
					name: "Priority",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 4,
				},
				{
					id: 274,
					type: "opportunity",
					key: "source",
					name: "Source",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 5,
				},
				{
					id: 275,
					type: "opportunity",
					key: "product",
					name: "Product",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 200,
					order: 6,
				},
				{
					id: 277,
					type: "opportunity",
					key: "company",
					name: "Company",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 100,
					order: 8,
				},
				{
					id: 278,
					type: "opportunity",
					key: "note",
					name: "Notes",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 100,
					order: 8,
				},
				{
					id: 279,
					type: "opportunity",
					key: "reminder",
					name: "Reminder",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 100,
					order: 9,
				},
				{
					id: 280,
					type: "opportunity",
					key: "address",
					name: "Address",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 100,
					order: 10,
				},
				{
					id: 281,
					type: "opportunity",
					key: "amount",
					name: "Amount",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 100,
					order: 11,
				},
				{
					id: 282,
					type: "opportunity",
					key: "added_at",
					name: "Created At",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 30,
					order: 12,
				},
				{
					id: 283,
					type: "opportunity",
					key: "added_by",
					name: "Created By",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 30,
					order: 13,
				},
				{
					id: 284,
					type: "opportunity",
					key: "action",
					name: "Action",
					sort: null,
					fixed: false,
					sortable: false,
					checkbox: false,
					visible: true,
					user_id: 0,
					width: 50,
					order: 14,
				},
			],
			reminderDataList: [],
		};
	},
	components: {
		UpdateReminderMenu,
		DetailTemplate,
		RejectedDialog,
		AcceptedDialog,
		Dialog,
		CommomListing,
		ReminderCompleteTemplate,
		DeleteTemplate,
		// ActivityTab: () => import("@/view/pages/leads/components/ActivityTab"),
		ActivityTabNew: () => import("@/view/pages/leads/components/ActivityTabNew"),
		MeetingTabNew: () => import("@/view/pages/leads/components/MeetingTabNew"),
		ReminderTabNew: () => import("@/view/pages/leads/components/ReminderTabNew"),
		CreateMeeting: () => import("@/view/pages/leads/create/CreateMeeting"),
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
		FullDialog: () => import("@/view/components/FullDialog"),
		AllNotesTab: () => import("@/view/pages/leads/components/NotesTab3"),
	},
	methods: {
		selectImage() {
			this.$refs["uploadImage"].$refs["input"].click();
		},
		uploadAttachment(file) {
			const _this = this;
			return new Promise((resolve, reject) => {
				try {
					_this.primaryLoader = true;
					let formData = new FormData();
					formData.append("files[0]", file, file.name);
					formData.append("parent_id", this.lead_id);
					formData.append("module", "contact");

					_this.$store
						.dispatch(UPLOAD, { url: "upload-lead ", data: formData })
						.then((response) => {
							console.log(response);
							this.contact_logo = response;
						})
						.catch((error) => {
							reject(error);
						})
						.finally(() => {
							_this.primaryLoader = false;
						});
				} catch (error) {
					reject(error);
				}
			});
		},
		handleTabChange(type) {
			if (type == "meeting") {
				if (!this.apimeeting) {
					this.getMeeting();
					this.apimeeting = true;
				}
			} else {
				if (!this.apireminder) {
					this.getReminder();
					this.apireminder = true;
				}
			}
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && data.length) {
						this.sourceList = data;
					}
					// this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		checkdate() {
			const singleMeetingDate = new Date(this.single_meeting?.start_from);
			const currentDate = new Date();

			if (singleMeetingDate < currentDate) {
				this.color = 1;
			} else {
				console.log("The meeting date is in the future.");
			}
		},
		checkdateReminder() {
			const singleMeetingDate = new Date(this.single_reminder?.start_date);
			const currentDate = new Date();

			if (singleMeetingDate < currentDate) {
				this.color1 = 1;
			} else {
				console.log("The meeting date is in the future.");
			}
		},
		getMeeting(type) {
			// if(this.opportunity){

			// }
			this.$store
				.dispatch(QUERY, {
					url: `meeting-contact-lead`,
					data: { related_to: "lead", leadId: this.$route.params.id, status: type ? type : 10 },
				})
				.then((data) => {
					this.meetingData = data.data_meeting;
					this.all_count_meeting = data.dbMeetingQuery;

					this.open_dialog.reminder = false;
					this.rerenderKey = Number(new Date());
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		reminderDel(row) {
			this.delTEXT = `(${row.barcode}) ` + row.title;
			this.delURL = `reminder/${row.id}`;
			this.confirm_dialog = true;
			/* this.$emit("reminderSuccess", row);
			this.$emit("reminderSuccessNew", row);
			 this.$emit("deleteSuccess", row); */
		},
		reminderComplete(remind) {
			console.log(remind, "remind");
			this.completeTEXT = `(${remind.barcode}) ` + remind?.title;
			this.completeURL = `reminder-complete/${remind.id}`;
			this.complete_dialog = true;
		},
		findContact() {
			this.id = this.$route.params.id;
			this.$store
				.dispatch(GET, {
					url: `find-single-contact/${this.id}`,
				})
				.then((response) => {
					this.contact = response?.data; // Assuming data is in response.data
					this.logData = response.logs;

					this.opportunity = response?.data?.deal;
					this.followstatus = response?.followstatus;
					this.meetingstatus = response?.meetingstatus;
					this.quotationstatus = response?.quotationstatus;
					this.notcontactedstatus = response?.notcontactedstatus;
					this.single_meeting = response?.meetings;
					this.single_reminder = response?.reminders;
					this.diffInDays = response?.diffInDays;
					this.contact_logo = response?.data.contact_logo; // Assuming data is in response.data
					this.editReminder = false;
					this.checkdate();
					this.checkdateReminder();

					console.log(this.logData, "this.logData");
				})
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		changeColor(type = "") {
			if (!type) return;
			if (type == "accept") {
				this.acceptDialog = true;
				this.isOppStatus = true;
				this.isAccepted = true;
				this.isRejected = false;
				this.activeTabClass = "green lighten-5 text-dark v-tab--active";
			}
			if (type == "reject") {
				this.acceptDialog = true;
				this.isOppStatus = true;
				this.isRejected = true;
				this.isAccepted = false;
				this.activeTabClass = "red lighten-5 text-dark v-tab--active";
			}
			if (type == "reopen") {
				this.isOppStatus = false;
				this.activeTabClass = "green darken-4 text-white v-tab--active";
			}
		},
		// findOpportunity() {
		// 	this.id = this.$route.params.id;

		// 	this.$store
		// 		.dispatch(GET, {
		// 			url: `find-single-opportunity/${this.id}`,
		// 		})

		// 		.then((response) => {
		// 			this.opportunityData = response?.deal[0];
		// 			this.logData = response?.logs;

		// 			if (response?.deal?.[0]?.dealproduct) {
		// 				this.opportunity.product_type = response.deal[0].dealproduct.map(
		// 					(product_type) => product_type.product_id
		// 				);
		// 			}
		// 			// console.log( this.opportunity.product_type,' this.opportunity.product_type')
		// 			// this.contact.tags = response?.data[0]?.tag;
		// 			// this.contact.company = response?.data[0]?.company?.id;

		// 			console.log(response);
		// 		})
		// 		.catch((error) => {
		// 			console.error("Error fetching contact:", error);
		// 		});
		// },
		truncate(text, length) {
			return text.length > length ? text.substring(0, length) + "..." : text;
		},
		showDescription(description) {
			this.fullDescription = description;
			this.desc_dialog = true;
		},
		open_meeting_drawer(name) {
			this.editMeetingData = {};
			this.attendieList = {};
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		open_reminder_drawer(name) {
			this.editReminderData = {};
			this.attendieList = {};
			this.open_dialog.reminder = true;
			this.meeting_title = name;
		},
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		refreshMeeting() {
			this.getMeeting();
		},
		goBackData() {
			const filterQuery = this.$route.query;
			console.log("filterQuery => ", filterQuery);
			this.$router.push({
				name: "contact",
				query: {
					...filterQuery,
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
		},
		deleteRefreshReminder(row) {
			this.getReminder(row.status);
		},
		refreshReminder(row) {
			console.log({ update: row });
			this.getReminder(row.status);
			this.findContact();

			// if(row.status_marked && row.status_marked =="complete"){
			// 	this.reminderTabs = "complate";
			// }
		},
		closeDialog() {
			this.leadsDialog = false;
		},
		getReminder(type) {
			//this.pageLoading = true;
			if (!type) {
				this.reminderTabs = "overdue";
				type = 1;
			}

			this.$store
				.dispatch(QUERY, {
					url: `reminder-contact-list`,
					data: { related_id: this.lead_id, related_to: "lead", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complate";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
					//this.singleLead();
					//console.log(this.reminderData );
					//this.pageLoading = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	mounted() {
		TaskEventBus.$on("refresh", () => {
			this.getListing();
		});
		// this.findOpportunity();
		this.findContact();
		this.getMeeting();
		this.getReminder();
		this.getSetting();
		this.viewType = this.$route.query.viewType;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contact", disabled: false, href: "/Contact" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.lead_id = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style scoped>
.showHideAction {
	opacity: 0;
}
.reminder-listing table tbody tr:hover .showHideAction {
	opacity: 1;
}
</style>
